/**
 * @generated SignedSource<<0a4dd52e5c5d33c46831fc628af2730b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CareType = "CAREER_COACHING" | "COACHING" | "HYBRID_CARE" | "THERAPEUTIC_STRUCTURED" | "THERAPEUTIC_UNSTRUCTURED";
export type WellbeingProfileFocusAreas = "EMOTIONAL" | "FINANCIAL" | "PHYSICAL" | "PROFESSIONAL" | "SLEEP";
import { FragmentRefs } from "relay-runtime";
export type WellbeingProfileScoreDisplay_wellbeingProfileResults$data = ReadonlyArray<{
  readonly createdAt: string;
  readonly recommendedCareType: CareType | null | undefined;
  readonly scores: ReadonlyArray<{
    readonly answers: ReadonlyArray<{
      readonly answer: string;
      readonly question: string;
    }>;
    readonly focusArea: WellbeingProfileFocusAreas;
    readonly score: number;
  }>;
  readonly wellbeingScore: number;
  readonly " $fragmentType": "WellbeingProfileScoreDisplay_wellbeingProfileResults";
}>;
export type WellbeingProfileScoreDisplay_wellbeingProfileResults$key = ReadonlyArray<{
  readonly " $data"?: WellbeingProfileScoreDisplay_wellbeingProfileResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"WellbeingProfileScoreDisplay_wellbeingProfileResults">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "WellbeingProfileScoreDisplay_wellbeingProfileResults",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wellbeingScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recommendedCareType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WellbeingProfileResultScore",
      "kind": "LinkedField",
      "name": "scores",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "focusArea",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WellbeingProfileResultAnswers",
          "kind": "LinkedField",
          "name": "answers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "question",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answer",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WellbeingProfileResult",
  "abstractKey": null
};

(node as any).hash = "6bc4e4798247176c4e114fdc1dc4e9f7";

export default node;
