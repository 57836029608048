import WellbeingProfileFocusAreas from "@olivahealth/graphql-server/src/domain/value-objects/WellbeingProfileFocusAreas";

export const THRESHOLD_SCORE_FOR_REGULAR_THERAPY = 41;
export const ABOVE_CASENESS_SCORE_THRESHOLD = 55;
export const BELOW_CASENESS_SCORE_THRESHOLD = 70;

export const NEEDS_GAD2_PHQ2_WBP_SCORE_LOWER_BOUND =
  THRESHOLD_SCORE_FOR_REGULAR_THERAPY;
export const NEEDS_GAD2_PHQ2_WBP_SCORE_HIGHER_BOUND =
  ABOVE_CASENESS_SCORE_THRESHOLD;

export const RECOMMEND_THERAPY_FOCUS_AREAS: WellbeingProfileFocusAreas[] = [
  WellbeingProfileFocusAreas.EMOTIONAL,
  WellbeingProfileFocusAreas.PHYSICAL,
  WellbeingProfileFocusAreas.SLEEP,
];
